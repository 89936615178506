import {createEventHandler} from '@wix/tpa-settings'
import {ExperimentNames, isRtlLanguage} from '@wix/wix-events-commons-statics'
import {ControllerParams, CreateControllerFn} from '@wix/yoshi-flow-editor'
import {setInstance, watchInstance} from '../../commons/actions/instance'
import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {subscribeToStateChanges} from '../../commons/utils/store'
import {getLanguage, getPageUrl} from '../../commons/utils/wix-code-api'
import {getStore} from './controller/create-store'
import {setSlotData} from './controller/slots'
import {subscribeToConsentPolicyChanges} from './Widget/actions/consent-policy'
import {getExportedActions} from './Widget/actions/exported-actions'
import {addLoginListener} from './Widget/actions/members'
import {addLocationListener, navigate} from './Widget/actions/navigation'
import {updateSettings} from './Widget/actions/settings'
import {DetailsPageProps} from './Widget/components/app/interfaces'
import {DetailsPageSettingsEventsKeys, DetailsSettingsNavigateActions, SettingsEvents} from './Widget/constants'

const createDetailsPageController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  const componentEventHandler = createEventHandler<SettingsEvents>(
    controllerParams.controllerConfig.config.publicData.COMPONENT || {},
  )
  let store = null

  return {
    updateConfig: (_, newConfig) => {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {})
    },
    updateAppSettings: async (_$w, action) => {
      if (store) {
        setTimeout(() => {
          store.dispatch(updateSettings(action as any))
        }, 100)
      }
    },
    async pageReady() {
      const {
        wixCodeApi,
        appParams: {instance},
      } = controllerParams.controllerConfig
      const {experiments} = controllerParams.flowAPI
      const language = getLanguage(wixCodeApi)
      const controller = controllerParams.controllerConfig
      const pageUrl = await getPageUrl(wixCodeApi)

      store = await getStore(controllerParams)
      store.dispatch(setInstance(instance))
      const state = store.getState()

      setSlotData(controllerParams, state)

      store.dispatch(addLoginListener())

      experiments.enabled(ExperimentNames.ConsentPolicy) &&
        subscribeToConsentPolicyChanges(store.dispatch, controllerParams.flowAPI)

      watchInstance(controller, store.dispatch)
      addLocationListener(controllerParams, store)

      const props: DetailsPageProps = {
        state,
        actions: getExportedActions(store),
        isRTL: isRtlLanguage(language),
        pageUrl,
        staticsBaseUrl: controller.appParams.baseUrls.staticsBaseUrl,
        ricosEnabled: !experiments.enabled(ExperimentNames.DisableRicos),
        useRichContentFromEventEnabled: experiments.enabled(ExperimentNames.UseRichContentFromEvent),
        // @ts-expect-error
        fitToContentHeight: true,
      }
      controller.setProps(props)
      subscribeToStateChanges(controller, store)

      componentEventHandler.on(
        DetailsPageSettingsEventsKeys.Navigate,
        (navigateAction: DetailsSettingsNavigateActions) => {
          if (navigateAction === DetailsSettingsNavigateActions.details) {
            store.dispatch(navigate(DETAILS_ROUTE.DETAILS) as any)
          }
          if (navigateAction === DetailsSettingsNavigateActions.form) {
            store.dispatch(navigate(DETAILS_ROUTE.FORM) as any)
          }
        },
      )
    },
  }
}

export default createDetailsPageController
